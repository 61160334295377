export class NotificationsPersonalEntry {

  constructor(public id: number,
              public type: PersonalNotificationType,
              public createdAt: string,
              public opened: boolean,
              public plateNumber: string,
              public vehicleId: number,
              public objectId: number,
              public objectInfo: string,
              public additionalInfo: string) {
  }
}

export enum PersonalNotificationType {
  GEOZONE_TIMER = 'GEOZONE_TIMER',
  GEO_ZONE_ENTER = 'GEO_ZONE_ENTER',
  TASK_TIMER = 'TASK_TIMER',
  IDLE_TIMER = 'IDLE_TIMER',
  FILLED_FORM = 'FILLED_FORM'
}
