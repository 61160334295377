import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {NotificationsAlarmsEntry} from './notifications.alarms.entry';
import {NotificationService} from '../../../core/notification/notification.service';
import {NotificationsNoticeEntry} from './notifications.notice.entry';
import {Router} from '@angular/router';
import {AlarmsWebSocketService} from '../../../core/ws/alarms-web-socket.service';
import {Subscription} from 'rxjs';
import {NoticeType} from '../../../core/model/notice-type';
import {NotificationsPersonalEntry, PersonalNotificationType} from './notifications.personal.entry';
import {SubscriptionUtils} from '../../../core/commons/subscription.utils';

@Component({
  selector: 'app-notifications',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./notifications.component.scss'],
  templateUrl: './notifications.component.html',
  providers: [NotificationsService]
})

export class NotificationsComponent implements OnInit, OnDestroy {
  public alarms: Array<NotificationsAlarmsEntry>;
  public notices: Array<NotificationsNoticeEntry>;
  public personalNotifications: Array<NotificationsPersonalEntry>;

  public notOpenedAlarmsCount = 0;
  public notOpenedNoticesCount = 0;
  public notOpenedPersonalNotificationsCount = 0;
  public selectedSection = 'ALARMS';

  private alarmCountSubs: Subscription;

  constructor(private notificationsService: NotificationsService,
              private notification: NotificationService,
              private alarmsWSService: AlarmsWebSocketService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.setupCountRefreshing();
  }

  private setupCountRefreshing() {
    this.alarmCountSubs = this.alarmsWSService.alarmCount$
      .subscribe(count => {
        this.notifyIfNewNotification(count, 'ALARM');
        this.notOpenedAlarmsCount = count;
      });
    this.alarmCountSubs = this.alarmsWSService.noticeCount$
      .subscribe(count => {
        this.notifyIfNewNotification(count, 'NOTICE');
        this.notOpenedNoticesCount = count;
      });
    this.alarmCountSubs = this.alarmsWSService.personalNotificationCount$
      .subscribe(count => {
        this.notifyIfNewNotification(count, 'PERSONAL');
        this.notOpenedPersonalNotificationsCount = count;
      });
  }

  public loadAlarmsAndNotices(element: HTMLAnchorElement) {
    let dropdownState = element.getAttribute('aria-expanded');
    if (dropdownState === 'false') {
      this.fetchDataByTab(this.selectedSection);
    }
  }

  private fetchDataByTab(tab: string): void {
    switch (tab) {
      case 'ALARMS':
        this.notificationsService.latestAlarms().subscribe(
          (alarms) => this.alarms = alarms,
          (err) => this.notification.loadingError()
        );
        break;
      case 'NOTICES':
        this.notificationsService.latestNotices().subscribe(
          (notices) => this.notices = notices,
          (err) => this.notification.loadingError()
        );
        break;
      case 'PERSONAL':
        this.notificationsService.loadPersonalNotices().subscribe(
          (personal) => this.personalNotifications = personal,
          (err) => this.notification.loadingError()
        );
        break;
      default:
        break;
    }
  }

  public markAllRead(selectedSection: string, e: MouseEvent) {
    this.dontCloseDropdown(e);
    switch (selectedSection) {
      case 'ALARMS':
        this.markAllAlarmsRead();
        return;
      case 'NOTICES':
        this.markAllNoticesRead();
        return;
      case 'PERSONAL':
        this.markAllPersonalNoticesRead();
        return;
    }
  }

  private markAllAlarmsRead() {
    this.notificationsService.markAllAlarmsAsRead().subscribe(
      () => {
        this.alarms.forEach(it => it.opened = true);
        this.notOpenedAlarmsCount = 0;
      },
      (err) => this.notification.loadingError()
    );
  }

  private markAllNoticesRead() {
    this.notificationsService.markAllNoticesAsRead().subscribe(
      () => {
        this.notices.forEach(it => it.opened = true);
        this.notOpenedNoticesCount = 0;
      },
      (err) => this.notification.loadingError()
    );
  }

  private markAllPersonalNoticesRead() {
    this.notificationsService.markAllPersonalNoticesAsRead().subscribe(
      () => {
        this.personalNotifications.forEach(it => it.opened = true);
        this.notOpenedPersonalNotificationsCount = 0;
      },
      (err) => this.notification.loadingError()
    );
  }

  public openNotice (notice: NotificationsNoticeEntry) {
    if (!notice.opened) {
      this.notificationsService.markNoticesAsRead(notice.id).subscribe(
        () => notice.opened = true
      )
    }
    // if navigation changing, also make changes in fh-mail.
    switch (notice.type) {
      case NoticeType.FORM_FILLED:
        this.checkIfExistsAndNavigate(notice);
        break;
      case NoticeType.EXTERNAL_TASK:
      case NoticeType.BEHIND_SCHEDULE:
      case NoticeType.OLD_STATUS:
        this.router.navigate(['/tasks/' + notice.vehicleId + '/manager/pro']);
        break;
      case NoticeType.SUB_TASK:
        const queryParams = {
          subtaskId: notice.objectId
        };
        this.router.navigate(['/tasks/' + notice.vehicleId + '/manager/pro'], {queryParams});
        break;
      case NoticeType.FORM_DISCREPANCY:
        let params = {
          firstForm: notice.objectId,
          secondForm: +notice.additionalInfo
        };
        this.router.navigate([`/documents/forms-comparison`], {queryParams: params});
        break;
      case NoticeType.NOTE_REMINDER:
        if (notice.additionalInfo === 'VEHICLE') {
          this.router.navigate([`/vehicles/${notice.vehicleId}/info`], {state: {fromNotice: true}});
        } else {
          this.router.navigate([`/drivers/${notice.driverId}/info`]);
        }
        break;
      case NoticeType.TRIP_SHEET_FILLED:
        this.router.navigate([`/documents/trip-sheets/${notice.objectId}`]);
        break;
      case NoticeType.EXPENSE_SHEET_FILLED:
        this.router.navigate([`/documents/expenses-sheets/${notice.objectId}`]);
        break;
      case NoticeType.SOS:
        break;
      case NoticeType.VIOLATION:
        let additionalInfos = notice.additionalInfo.split(' / ');
        this.router.navigate(
          [`/reports/tacho-faults`],
          { queryParams: {driverCard: additionalInfos[0], from: additionalInfos[3], till: additionalInfos[3]}}
        );
        break;
      default:
        throw new Error(`Unsupported type ${notice.type}`);
    }
  }

  public openPersonalNotice (notification: NotificationsPersonalEntry) {
    if (!notification.opened) {
      this.notificationsService.markPersonalNoticeAsRead(notification.id, notification.type).subscribe();
    }
    switch (notification.type) {
      case PersonalNotificationType.GEOZONE_TIMER:
      case PersonalNotificationType.GEO_ZONE_ENTER:
        let params = {
          geoZone: notification.objectId,
          plateNumber: notification.plateNumber,
          notificationDate: notification.createdAt,
          minutesBehind: PersonalNotificationType.GEOZONE_TIMER === notification.type
            ? notification.additionalInfo
            : 5
        };
        this.router.navigate([`/geo-zones/hits`], {queryParams: params});
        break;
      case PersonalNotificationType.TASK_TIMER:
        this.router.navigate([`/reports/tasks-report/${notification.vehicleId}/${notification.objectId}`])
        break;
      case PersonalNotificationType.IDLE_TIMER:
        this.router.navigate([`/tasks/${notification.vehicleId}/manager/pro`])
        break;
      case PersonalNotificationType.FILLED_FORM:
        this.router.navigate([`/documents/vehicle-change/${notification.objectId}`]);
        break;
      default:
        throw new Error(`Unsupported type ${notification.type}`);
    }
  }

  private checkIfExistsAndNavigate(notice: NotificationsNoticeEntry) {
    this.notificationsService.formExists(notice.objectId)
      .subscribe(
        exists => this.navigateToNoticeIfExists(exists, notice.objectId),
        err => this.notification.sendError()
      );
  }

  private navigateToNoticeIfExists(exists: boolean, objectId: number) {
    if (exists) {
      this.router.navigate(['/documents/vehicle-change/' + objectId]);
    } else {
      this.notification.loadingNoData()
    }
  }

  public selectSection(sectionName: string, e: MouseEvent) {
    this.selectedSection = sectionName;
    this.fetchDataByTab(sectionName);
    this.dontCloseDropdown(e);
  }

  private dontCloseDropdown(e: MouseEvent) {
    e.stopPropagation();
  }

  public getTotalUnreadCount(): number {
    return this.notOpenedAlarmsCount + this.notOpenedNoticesCount + this.notOpenedPersonalNotificationsCount;
  }

  public ngOnDestroy(): void {
    SubscriptionUtils.safeUnsubscribe(this.alarmCountSubs);
  }

  private notifyIfNewNotification(count: number, type: string) {
    if (type === 'ALARM') {
      if (this.notOpenedAlarmsCount && count > this.notOpenedAlarmsCount) {
        this.notification.info('global.alert.newAlarm');
      }
    } else if (type === 'NOTICE') {
      if (this.notOpenedNoticesCount && count > this.notOpenedNoticesCount) {
        this.notification.info('global.alert.newNotice');
      }
    }
  }

}
