import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TaskFormData} from './task-form/task-form.data';
import {MapPosition} from '../route/metamodel';
import {DirectionsRequest} from '../../../../core/direction/directions-request';
import {IdValue} from '../../../../core/model/id-value';
import {RouteRequest} from './model/route-request';
import {TaskEntry} from './tasks-queue/model/task.entry';
import {TasksQueueEntry} from './tasks-queue/model/tasks-queue.entry';
import {TaskUtils} from './utils/task-utils';
import {VehiclePosition} from '../../../../core/component/latest-vehicles-info/vehicle-position';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HttpUtils} from '../../../../core/page/commons/http.utils';
import {InfoOptionEntry} from './tasks-queue/model/info-option.entry';
import {VehicleParametersForm} from '../../vehicles/vehicles-list/vehicle-info/vehicle-parameters/model/vehicle-parameters.component';
import {TaskDateForm} from '../../reports/task/model/task/task-date.form';
import {TaskDateResponse} from '../../reports/task/model/task/task-date.response';
import {OrderPdfForm} from '../../orders/orders/list/model/order-pdf/order-pdf.form';
import {map} from 'rxjs/operators';
import {SubTasksReportItem} from '../pro-tasks-manager/subtasks/model/sub-tasks-report.item';
import {SubtaskStatus} from '../pro-tasks-manager/subtasks/model/subtask-status';

@Injectable()
export class TasksManagerService {

  constructor(private http: HttpClient) {
  }

  public persist(
    vehicleId: number,
    taskBefore: number,
    nextTask: number,
    form: TaskFormData,
    fuelLevel: number): Observable<IdValue<string>> {

    let before = taskBefore ? taskBefore : -1;
    let next = nextTask ? nextTask : -1;
    return this.http.post<IdValue<string>>(`/api/tasks/${vehicleId}?taskBefore=${before}&nextTask=${next}&fuelLevel=${fuelLevel}`, form);
  }

  public insert(
    vehicleId: number,
    form: TaskFormData,
    taskBefore: number,
    nextTask: number,
    fuelLevel: number): Observable<IdValue<string>> {

    return this.http.post<IdValue<string>>(
      `/api/tasks/${vehicleId}/insert?taskBefore=${taskBefore != null ? taskBefore : -1}&nextTask=${nextTask}&fuelLevel=${fuelLevel}`,
      form);
  }

  public moveUp(vehicleId: number, id: number, taskBefore: number, nextTask: number): Observable<Response> {
    return this.http.post<Response>(`/api/tasks/${vehicleId}/move-up?id=${id}&taskBefore=${taskBefore}&nextTask=${nextTask}`, {});
  }

  public moveDown(vehicleId: number, id: number, nextTask: number, nextNextTask: number): Observable<Response> {
    return this.http.post<Response>
    (`/api/tasks/${vehicleId}/move-down?id=${id}&nextTask=${nextTask}&nextNextTask=${nextNextTask}`, {});
  }

  public queue(vehicleId: number, position: VehiclePosition): Observable<TasksQueueEntry> {
    let latitude = position != null ? position.latitude : 0.0;
    let longitude = position != null ? position.longitude : 0.0;
    return this.http.get<TasksQueueEntry>(`/api/tasks/${vehicleId}?lat=${latitude}&lng=${longitude}`);
  }

  public doneTasks(vehicleId: number, page: number): Observable<TaskEntry[]> {
    return this.http.get<TaskEntry[]>(`/api/tasks/${vehicleId}/done-tasks?page=${page}`);
  }

  public doneTasksTillPage(vehicleId: number, page: number): Observable<TaskEntry[]> {
    return this.http.get<TaskEntry[]>(`/api/tasks/${vehicleId}/paged/done-tasks?page=${page}`);
  }

  public delete(vehicleId: number, taskId: number, nextTask: number): Observable<Response> {
    return this.http.delete<Response>(`/api/tasks/${vehicleId}/${taskId}?nextTask=${nextTask}`);
  }

  public deleteAll(id: number): Observable<Response> {
    return this.http.delete<Response>(`/api/tasks/${id}/delete-all`);
  }

  public resetStatus(id: number, vehicleId: number, fuelLevel: number): Observable<Response> {
    return this.http.post<Response>(`/api/tasks/${vehicleId}/reset?task=${id}&fuelLevel=${fuelLevel}`, {});
  }

  public confirmTask(id: number, vehicleId: number): Observable<Response> {
    return this.http.post<Response>(`/api/tasks/${vehicleId}/confirm?task=${id}`, {});
  }

  public confirmAll(id: number): Observable<Response> {
    return this.http.post<Response>(`/api/tasks/${id}/confirm-all`, {});
  }

  public markAsDone(id: number, vehicleId: number, nextTask: number): Observable<Response> {
    return this.http.post<Response>(`/api/tasks/${vehicleId}/mark-as-done?task=${id}&nextTask=${nextTask}`, {});
  }

  public nextStartPosition(vehicleId: number, taskBefore: number): Observable<MapPosition> {
    return this.http.get<MapPosition>(`/api/tasks/${vehicleId}/next-start-position?taskBefore=${taskBefore}`);
  }

  public path(vehicleId: number, taskId: number): Observable<string> {
    return this.http.get(`/api/tasks/${vehicleId}/${taskId}/path`, {responseType: 'text'});
  }

  /*-------------------------------------------------------------------------------------------*/

  public truckRoute(request: RouteRequest, vehicleId: number, routeDistance: number, zoom: number): Observable<any> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'mapZoom', zoom);
    return this.http.post(`/api/routing/truck-route`, request, {params: params});
  }

  public googleDirections(vehicleId: number, request: DirectionsRequest, waypoints: MapPosition[]): Observable<string> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'origin.lat', `${request.origin.lat}`);
    params = HttpUtils.appendParam(params, 'origin.lng', `${request.origin.lng}`);
    params = HttpUtils.appendParam(params, 'destination.lat', `${request.destination.lat}`);
    params = HttpUtils.appendParam(params, 'destination.lng', `${request.destination.lng}`);

    waypoints.forEach((it, idx) => {
      let waypoint = TaskUtils.toMapPosition(it);
      params = HttpUtils.appendParam(params, `waypoints[${idx}].lat`, waypoint.lat);
      params = HttpUtils.appendParam(params, `waypoints[${idx}].lng`, waypoint.lng);
    });
    params = HttpUtils.appendParam(params, 'avoids', `${request.avoids}`);

    return this.http.get<string>(`/api/tasks/${vehicleId}/google-directions`, {params: params});
  }

  /*-------------------------------------------------------------------------------------------*/

  public driverLanguage(vehicleId: number): Observable<any> {
    return this.http.get(`/api/tasks/${vehicleId}/driver-language`, {responseType: 'text'});
  }

  public markAsExecuting(id: number, vehicleId: number): Observable<any> {
    return this.http.post(`/api/tasks/${vehicleId}/mark-as-executing?taskId=${id}`, {});
  }

  /*-------------------------------------------------------------------------------------------*/

  public translationFile(lang: string): Observable<any> {
    return this.http.get(`assets/i18n/${lang.toLowerCase()}.json`, {headers: {skip: 'true'}});
  }

  public options(): Observable<InfoOptionEntry[]> {
    return this.http.get<InfoOptionEntry[]>(`/api/tasks-options`);
  }

  public saveAdditional(vehicleId: number, taskBefore: number, nextTask: number, additionalTasks: any): Observable<any> {
    let before = taskBefore ? taskBefore : -1;
    let next = nextTask ? nextTask : -1;
    return this.http.post(`/api/tasks/${vehicleId}/additional/?taskBefore=${before}&nextTask=${next}`, additionalTasks);
  }

  public vehicleParams(vehicleId: number) {
    return this.http.get<VehicleParametersForm>(`/api/vehicle-params?vehicleId=${vehicleId}`)
  }

  public saveFile(id: number, file: any, vehicleId: number): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`/api/tasks/${vehicleId}/file?id=${id}`, formData, {
      headers: new HttpHeaders()
    });
  }

  public downloadFile(id: number, vehicleId: number): Observable<Blob> {
    return this.http.post(`/api/tasks/${vehicleId}/file/download`, id, {responseType: 'blob'})
      .pipe(map(res => <Blob>res));
  }

  public getActualRoute(vehicleId: number, deviceKey: string, startedAt: string): Observable<string> {
    return this.http.get(`/api/tasks/${vehicleId}/actual-route?startedAt=${startedAt}`, {responseType: 'text'})
  }

  public createSubTask(objectType: string, vehicleId: number, objectId: number, comment: string): Observable<void> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'comment', comment);
    params = HttpUtils.appendParam(params, 'objectId', objectId);
    params = HttpUtils.appendParam(params, 'vehicleId', vehicleId);
    params = HttpUtils.appendParam(params, 'objectType', objectType);
    return this.http.put<void>(`/api/sub-tasks/create`, {}, {params: params});
  }

  public createFillViolationFormSubTask(request: any): Observable<void> {
    return this.http.post<void>('/api/sub-tasks/create-violation-form-sub-task', request);
  }

  public deleteSubTask(id: number) {
    return this.http.delete(`/api/sub-tasks/delete/${id}`);
  }

  public updateTaskDate(form: TaskDateForm, vehicleId: number): Observable<string> {
    return this.http.post(`/api/tasks/${vehicleId}/update-date`, form, { responseType: 'text' });
  }

  public getSubTasks(vehicleId: number, status: SubtaskStatus, page: number): Observable<SubTasksReportItem[]> {
    let params = new HttpParams();
    params = HttpUtils.appendParam(params, 'status', status);
    params = HttpUtils.appendParam(params, 'page', page);
    return this.http.get<SubTasksReportItem[]>(`/api/sub-tasks/${vehicleId}`, {params: params});
  }
}
