import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({name: 'taskTypeStatus'})
export class TaskTypeStatusPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  public transform(value: string): string {
      return this.translateTaskTypeStatus(value);
  }

  private translateTaskTypeStatus(value: string) {
    let values = value.split(",");
    return this.translate.instant('proTasks.type.' + values[0]) + "-" + this.translate.instant('proTasks.queue.status.' + values[1]);
  }
}
