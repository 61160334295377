<ul class="notification-menu__notifications no-print">
  <li class="dropdown notification-menu">
    <a href #dropdownElement class="dropdown-toggle"
       title="{{'navbar.notifications' | translate}}"
       data-toggle="dropdown"
       aria-expanded="false"
       (click)="loadAlarmsAndNotices(dropdownElement)"
       [ngClass]="getTotalUnreadCount()>0?'red':'green'">
      <i class="fa fa-bell-o"></i>
      <div *ngIf="getTotalUnreadCount()>=0" class="notification__indicator" >
        <span>{{getTotalUnreadCount()}}</span>
        <div class="pulsate"></div>
      </div>
    </a>
    <div class="notification__body dropdown-menu">
      <nav class="notification__tabs">
        <a class="tabs__link" role="button" (click)="selectSection('ALARMS', $event)" [ngClass]="notOpenedAlarmsCount > 0 ? 'red' : 'green'">
          {{'messages.alarms.alarms' | translate}}
          <span class="notification__indicator" *ngIf="notOpenedAlarmsCount >= 0">
            <span>{{notOpenedAlarmsCount}}</span>
          </span>
        </a>
        <a class="tabs__link" role="button" (click)="selectSection('NOTICES', $event)" [ngClass]="notOpenedNoticesCount > 0 ? 'red' : 'green'">
          {{'messages.notices.notices' | translate}}
          <span class="notification__indicator" *ngIf="notOpenedNoticesCount >= 0">
            <span>{{notOpenedNoticesCount}}</span>
          </span>
        </a>
        <a class="tabs__link" role="button"
           (click)="selectSection('PERSONAL', $event)" [ngClass]="notOpenedPersonalNotificationsCount > 0 ? 'red' : 'green'">
          {{'messages.personal.title' | translate}}
          <span class="notification__indicator" *ngIf="notOpenedPersonalNotificationsCount >= 0">
            <span>{{notOpenedPersonalNotificationsCount}}</span>
          </span>
        </a>
        <a href="javascript:void(0)" (click)="markAllRead(selectedSection, $event)" class="tabs__link tabs__link--action">
          {{'messages.alarms.allRead' | translate}}
        </a>
      </nav>

      <div *ngIf="selectedSection === 'ALARMS'">
        <div class="ntf-list" slim-scroll [slimScrollOptions]="{height: 250}">
          <a *ngFor="let alarm of alarms"
             class="ntf__item transition  cursor-pointer"
             [ngClass]="alarm.opened ? 'ntf__item--read' : 'ntf__item--new'"
             [routerLink]="['/alarms', alarm.id]">
            <span class="ntf__img">
              <i class="ntf-type--{{alarm.type}} ntf-unresolved--{{alarm.localEndTime == null}}"></i>
            </span>
            <div class="ntf__msg">
              <h3>{{'messages.alarms.' + alarm.type | translate}}</h3>
              <p>{{alarm.plateNumber}}</p>
            </div>
            <div class="ntf__date">
              <p>{{alarm.localStartTime}}</p>
              <p>{{alarm.localEndTime}}</p>
            </div>
          </a>
        </div>
        <div class="ntf-list__footer">
          <a href="#" [routerLink]="['/alarms']">{{'messages.alarms.viewAll' | translate}}</a>
        </div>
      </div>

      <div *ngIf="selectedSection === 'NOTICES'">
        <div class="ntf-list" slim-scroll [slimScrollOptions]="{height: 250}">
          <a *ngFor="let notice of notices"
             class="ntf__item transition  cursor-pointer"
             [ngClass]="notice.opened? 'ntf__item--read' : 'ntf__item--new'"
             (click)="openNotice(notice)"
             href="javascript:void(0)">
            <span class="ntf__img">
              <i class="ntf-type--{{notice.type}}"></i>
            </span>
            <div class="ntf__msg list-content-notices">
              <h3>{{'messages.notices.' + notice.type | translate}}</h3>
              <p>{{notice.plateNumber}}</p>
              <p *ngIf="notice.additionalInfo != null"  class="nft__details">
                <ng-container *ngIf="notice.type === 'SUB_TASK'">
                  {{'notices.list.subTaskType.' + notice.additionalInfo | translate}}
                </ng-container>
                <ng-container *ngIf="notice.type === 'EXTERNAL_TASK'">
                  {{notice.additionalInfo}}
                </ng-container>
                <ng-container *ngIf="notice.type === 'NOTE_REMINDER'
                                  && notice.additionalInfo === 'DRIVER'
                                  && notice.driverName != null">
                  {{notice.driverName}}
                </ng-container>
                <ng-container *ngIf="notice.type === 'VIOLATION'">
                  {{notice | violationNotice}}
                </ng-container>
              </p>
            </div>
            <div class="ntf__date">
               <p>{{notice.createdAt}}</p>
            </div>
          </a>
        </div>
        <div class="ntf-list__footer">
          <a href="#" [routerLink]="['/notices']">{{'messages.notices.viewAll' | translate}}</a>
        </div>
      </div>

      <div *ngIf="selectedSection === 'PERSONAL'">
        <div class="ntf-list" slim-scroll [slimScrollOptions]="{height: 250}">
          <a *ngFor="let notification of personalNotifications"
             class="ntf__item transition  cursor-pointer"
             [ngClass]="notification.opened ? 'ntf__item--read' : 'ntf__item--new'"
             (click)="openPersonalNotice(notification)"
             href="javascript:void(0)">
            <span class="ntf__img">
              <i class="ntf-type--GEOZONE_TIMER"></i>
            </span>
            <div class="ntf__msg list-content-notices">
              <h3>{{'messages.personal.' + notification.type | translate}}</h3>
              <p>{{notification.plateNumber}}</p>
              <p *ngIf="notification.additionalInfo != null" class="nft__details">
                <ng-container *ngIf="notification.type === 'GEOZONE_TIMER' && notification.objectInfo != null">
                  {{'personal.list.additionalInfo.geozoneFirst' | translate}}
                  {{notification.objectInfo}}
                  {{'personal.list.additionalInfo.geozoneSecond' | translate}}
                  {{+notification.additionalInfo | duration:['MINUTES_TO_HOURS']}}
                </ng-container>
                <ng-container *ngIf="notification.type === 'TASK_TIMER'">
                  {{'personal.list.additionalInfo.taskTimerFirst' | translate}}
                  {{notification.objectInfo | taskTypeStatus}}
                  <br>
                  {{'personal.list.additionalInfo.taskTimerSecond' | translate}}
                  {{+notification.additionalInfo | duration:['MINUTES_TO_HOURS']}}
                </ng-container>
                <ng-container *ngIf="notification.type === 'IDLE_TIMER'">
                  {{'personal.list.additionalInfo.idleTimerFirst' | translate}}
                  {{notification.objectId | duration:['MINUTES_TO_HOURS']}}
                  <br>
                  {{'personal.list.additionalInfo.idleTimerSecond' | translate}}
                  {{+notification.additionalInfo | duration:['MINUTES_TO_HOURS']}}
                </ng-container>
                <ng-container *ngIf="notification.type === 'FILLED_FORM'">
                  {{'personal.list.additionalInfo.filledForm' | translate}}
                </ng-container>
                <ng-container *ngIf="notification.type === 'GEO_ZONE_ENTER' && notification.additionalInfo != null">
                  <div *ngIf="notification.additionalInfo.split(' // ')[0] != null">
                    {{'personal.list.additionalInfo.geozoneFirst' | translate}}
                    {{notification.additionalInfo.split(' // ')[0]}}
                  </div>
                  <div *ngIf="notification.additionalInfo.split(' // ')[1] != null">
                    {{'personal.list.additionalInfo.geozoneAddress' | translate}}
                    {{notification.additionalInfo.split(' // ')[1]}}
                  </div>
                </ng-container>
              </p>
            </div>
            <div class="ntf__date">
              <p>{{notification.createdAt}}</p>
            </div>
          </a>
        </div>
        <div class="ntf-list__footer">
          <a href="#" [routerLink]="['/personal']">{{'messages.notices.viewAll' | translate}}</a>
        </div>
      </div>
    </div>
  </li>
</ul>
