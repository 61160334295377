import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AuthStorage} from './auth-storage';
import {Permissions} from './permissions';
import {Role} from '../model/profile';
import {AuthService} from '../../auth/auth.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {take} from 'rxjs/operators';
import {map} from 'rxjs';

@Injectable()
export class IsLoggedIn implements CanActivate {

  private readonly oidcSecurityService = inject(OidcSecurityService);

  constructor(private router: Router,
              private authService: AuthService) {
  }

  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot): Promise<boolean> {

    let navigateUrl = AuthConfig.LOGIN_ROUTE;
    // let isAuthenticated = await this.isAuthenticated();
    if (AuthStorage.isAccessTokenValid()) {
      let isPasswordExpired = await this.isPasswordExpired();
      let roles: Role[] = route.data['roles'];
      navigateUrl = AuthConfig.CLIENT_MAIN_PAGE;
      if (isPasswordExpired && state.url !== AuthConfig.CHANGE_PASSWORD_ROUTE) {
        AuthStorage.saveStateUrl(state.url);
        this.router.navigate([AuthConfig.CHANGE_PASSWORD_ROUTE]);
        return true;
      } else if (!roles || Permissions.hasRoles(roles)) {
        return true;
      }
    }

    AuthStorage.saveStateUrl(state.url);
    this.router.navigate([navigateUrl]);
    return false;
  }

  private async isPasswordExpired(): Promise<boolean> {
    let isPasswordExpired = AuthStorage.isPasswordExpired();
    if (isPasswordExpired == null) {
      await this.authService.isPasswordExpired().toPromise().then(res => {
        isPasswordExpired = res;
        AuthStorage.persistPasswordExpiration(res);
      }).catch(() => isPasswordExpired = false);
    }
    return isPasswordExpired;
  }

  private async isAuthenticated(): Promise<boolean> {
    let authenticated = false;
    await this.oidcSecurityService.isAuthenticated().toPromise().then(res => {
      authenticated = res;
    })
    return authenticated;
  }
}
