import {Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie';
import {ChatService} from './core/component/chat/chat.service';
import {UpdateService} from './update.service';
import {VersionCheckService} from './version-check.service';
import {environment} from '../environments/environment';
import {addRussianFont} from '../assets/fonts/Lora-ru';
import {AlarmsWebSocketService} from './core/ws/alarms-web-socket.service';
import {ChatWebSocketService} from './core/ws/chat-web-socket.service';
import {AssetsStateWebSocketService} from './core/ws/assets-state/assets-state-web-socket.service';
import {LocaleService} from './core/global/locale.service';
import {Router, Event, NavigationStart} from '@angular/router';
import {Userpilot} from 'userpilot';
import {FM_LANG} from './core/global/cookies.constants';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {EventTypes, LoginResponse, OidcSecurityService, PublicEventsService} from 'angular-auth-oidc-client';
import {filter, takeUntil} from 'rxjs/operators';
import {GlobalService} from './core/global/global.service';
import {AuthStorage} from './core/auth/auth-storage';
import {AuthConfig} from './core/auth/auth-config';
import {DeviceService} from './core/commons/device.service';
import {isPartner, Profile} from './core/model/profile';

@Component({
  selector: 'app-root',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private readonly eventService = inject(PublicEventsService);
  private readonly oidcSecurityService = inject(OidcSecurityService);

  public currentURL = '';

  constructor(private router: Router,
              private translate: TranslateService,
              private cookieService: CookieService,
              private chatService: ChatService,
              private globalService: GlobalService,
              private deviceService: DeviceService,
              private versionCheckService: VersionCheckService,
              private update: UpdateService,
              private angularFireMessaging: AngularFireMessaging,
              private localeService: LocaleService) {
  }

  public ngOnInit(): void {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe(data => {
        this.oidcSecurityService.isAuthenticated().subscribe(data => {
          if (data) {
            let promise = async () => await this.getAccessToken();
            promise().then(() => {
              let redirectTo = AuthStorage.stateUrl() || AuthConfig.DEFAULT_ROUTE;
              AuthStorage.clearStateUrl();
              this.globalService.getLoginDetails()
                .subscribe({
                  next: (loggedUser) => {
                    AuthStorage.updateUserSettings(loggedUser, true, this.cookieService);
                    this.deviceService.isMobile()
                      ? Userpilot.destroy()
                      : this.initializeUserPilot(loggedUser);
                    this.router.navigate([redirectTo]);
                  },
                  error: () => {
                    localStorage.removeItem('local-authorization');
                    this.router.navigate([AuthConfig.LOGIN_ROUTE])
                  }
                })
            });
          }
        })
      })
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;
      });
    this.reloadUserPilot();
    this.update.checkForUpdates();
    if (environment.production) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    }
    this.translate.setDefaultLang('en');
    let lang = this.cookieService.get(FM_LANG);
    if (lang) {
      this.translate.use(lang);
      this.localeService.loadLocale(lang);
    } else {
      this.translate.use('en');
      this.localeService.loadLocale('en');
    }
    this.addJsPDFFonts();

    this.angularFireMessaging.messages.subscribe((message) => {
      // TODO: send message through our notification service. (never worked in production, need to check for priority)
    })
  }

  private reloadUserPilot(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.currentURL === '') {
          this.currentURL = event.url;
        } else if (this.currentURL !== event.url && event.url !== '/') {
          this.currentURL = event.url;
          Userpilot.reload();
        }
      }
    });
  }

  private initializeUserPilot(user: Profile): void {
    let lang = this.cookieService.get(FM_LANG);
    let companyId = user.company.id;
    Userpilot.identify(user.id.toString(), {
      name: user.fullName,
      email: user.email,
      locale_code: lang ? lang : 'en',
      company: {
        id: isPartner(user.role.toString()) ? 'P' + companyId : companyId,
        name: user.company.value
      }
    });
  }

  private addJsPDFFonts(): void {
    addRussianFont();
  }

  private async getAccessToken(): Promise<void> {
    let accessToken;
    await this.oidcSecurityService.getAccessToken().toPromise().then(token => accessToken = token)
    AuthStorage.setAccessToken(accessToken);
  }

}
