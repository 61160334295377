import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {PageNotFoundComponent} from './core/component/page-not-found';
import {VehicleCheckComponent} from './pages/open/check/vehicle/vehicle-check.component';

export const routes: Routes = [
  {path: '', loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule)},
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: 'check-device', component: VehicleCheckComponent},
  {path: 'response-microsoft'},
  {path: 'callback'},
  {path: '**', redirectTo: 'page-not-found', pathMatch: 'full'},
  {path: 'page-not-found', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
