<div class="notification-menu__bug dropdown">
    <a #bugDropdown
       class="dropdown-toggle"
       title="{{'navbar.bug-report' | translate}}"
       href="#"
       data-toggle="dropdown"
       aria-expanded="false"
       role="button">
      <i class="fa fa-bug"></i>
    </a>

    <nav class="bug-report-form dropdown-menu">
      <div class="bug-report-form__actions">
        <button class="filter__action--tertiary bug-report-form__action-back" *ngIf="selectedType" (click)="return($event)">
          <i class="ico-arrow-left" aria-hidden="true"></i>
          {{'messages.bug-report.return' | translate}}
        </button>
        <button class="filter__action--tertiary" (click)="cancel()">
          <i class="ico-close"></i>
          {{'messages.alarms.cancel' | translate}}
        </button>
        <button class="filter__action--primary"
                [disabled]="!bugReportForm.valid"
                (click)="send($event)">
          <i class="ico-send"></i>
          {{'messages.alarms.send' | translate}}
        </button>
        <button class="filter__action--secondary"
                [disabled]="!selectedType || screenshot != null"
                (click)="addScreenshot()">
          <i class="ico-image" aria-hidden="true"></i>
          {{'messages.alarms.screenshot' | translate}}
        </button>
        <button class="filter__action--danger"
                *ngIf="screenshot != null"
                (click)="removeScreenshot($event)">
          <span class="decor m-auto pr-1"><i class="ico-paperclip" aria-hidden="true"></i></span>
          <span class="screenshot-remove-icon"><i class="ico-close" aria-hidden="true"></i></span>
        </button>
        <button class="filter__action--secondary"
                title="{{'messages.bug-report.availableFormats' | translate}}"
                [disabled]="!selectedType || file != null"
                (click)="addFile($event)">
          <input #fileUpload
                 class="hidden-file-input"
                 type="file"
                 accept="{{availableFileFormats}}"
                 (change)="fileChange($event)"
                 aria-hidden="true"
                 />
          <i class="ico-document" aria-hidden="true"></i>
          {{'messages.bug-report.attachFile' | translate}}
        </button>
        <button class="filter__action--danger"
                *ngIf="file != null"
                (click)="removeFile($event)">
          <span class="decor m-auto pr-1"><i class="ico-paperclip" aria-hidden="true"></i></span>
          <span class="screenshot-remove-icon"><i class="ico-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <form [formGroup]="bugReportForm">
        <div class="bug-report-form__header" *ngIf="!selectedType">
          {{'messages.bug-report.select' | translate}}
        </div>
        <div *ngIf="!selectedType" class="bug-report-form__options">
          <button class="filter__action--tertiary"
                  (click)="setBugType($event, BugType.TAB_DEVICE)">
            <i class="fa fa-tablet sz-l"></i>
            {{'messages.bug-report.TAB_DEVICE' | translate}}
          </button>
          <button class="filter__action--tertiary"
                  (click)="setBugType($event, BugType.DEVICE_UNAVAILABLE)">
            <i class="ico-wifi-off sz-l"></i>
            {{'messages.bug-report.DEVICE_UNAVAILABLE' | translate}}
          </button>
          <button class="filter__action--tertiary"
                  (click)="setBugType($event, BugType.DDD_FILES)">
            <i class="fa fa-tachometer sz-l"></i>
            {{'messages.bug-report.DDD_FILES' | translate}}
          </button>
          <button class="filter__action--tertiary"
                  (click)="setBugType($event, BugType.SERVICES)">
            <i class="fa fa-wrench sz-l"></i>
            {{'messages.bug-report.SERVICES' | translate}}
          </button>
          <button class="filter__action--tertiary"
                  (click)="setBugType($event, BugType.OTHER)">
            {{'messages.bug-report.OTHER' | translate}}
          </button>
        </div>

        <div class="bug-report-form__header" *ngIf="selectedType">
          {{'messages.bug-report.' + selectedType | translate}}
        </div>
        <div class="bug-report-form__body" *ngIf="selectedType">
          <app-bug-type *ngIf="selectedType"
                        [form]="bugReportForm"
                        [bugType]="selectedType" >
          </app-bug-type>

          <div class="form-group" *ngIf="selectedType">
            <textarea placeholder="{{selectedType == BugType.SERVICES
                                   ? ('messages.alarms.services-text' | translate)
                                   : ('messages.alarms.bug-text' | translate)}}"
                      type="text"
                      id="text"
                      class="form-control"
                      maxlength="400"
                      formControlName="text">
            </textarea>
          </div>
          <div class="form-group" *ngIf="selectedType && failedCaptcha">
            <input type="text"
                   formControlName="verificationCode"
                   class="form-control"
                   placeholder="{{'login.verification.placeHolder' | translate}}"
                   appTrimInputValue />
            <ngb-alert [type]="'info'" [dismissible]="false">
              {{'pswd-reminder.failedCaptcha' | translate}}
            </ngb-alert>
          </div>
        </div>
      </form>
    </nav>
</div>
