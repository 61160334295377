import {Injectable} from '@angular/core';
import {AbstractSecurityStorage} from 'angular-auth-oidc-client';

@Injectable()
export class AuthStorageService implements AbstractSecurityStorage {

  public write(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public read(key: string): string | null {
    return localStorage.getItem(key);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }

}
