import {KeyboardCommands} from './keyboard-commands';
import {MessageSortType} from '../../../../../../core/component/chat/model/message-sort.type';

export class ChatConfigsForm {
  constructor(public enter: string,
              public shiftEnter: string,
              public messageSort: MessageSortType) {
  }

  public static defaultValues(): ChatConfigsForm {
    return new ChatConfigsForm(
      KeyboardCommands.ENTER,
      KeyboardCommands.SHIFT_ENTER,
      MessageSortType.SENT_ON);
  }
}
